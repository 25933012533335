<template>
  <div class="container auth-page">
    <div class="row logo-row">
      <div class="col-12">
        <img
          class="img-fluid"
          src="@/assets/img/logo-batters.png"
          alt="Logo Batters"
        />
      </div>
    </div>
    <div class="row auth-row">
      <div class="col-12 col-lg-6">
        <img class="img-fluid home-img" src="@/assets/img/batters-info.png" />
      </div>
      <div class="col-12 col-lg-6">
        <div class="auth-form">
          <h2 class="title text-center mb-4">Welcome back !</h2>
          <app-form
            :form="form"
            :model="model"
            :rules="rules"
            button="Login"
            button-side="center"
            @submitted="login"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { form, model, rules } from '@/utils/config/login';

export default {
  data() {
    return {
      form: [],
      model: {},
      rules: {},
    };
  },
  created() {
    this.form = JSON.parse(JSON.stringify(form));
    this.model = JSON.parse(JSON.stringify(model));
    this.rules = JSON.parse(JSON.stringify(rules));
  },
  methods: {
    async login(data) {
      try {
        await this.$store.dispatch('login', {
          email: data.email,
          password: data.password,
        });
        this.$router.push({ name: 'AdminDashboard' });
      } catch (err) {
        return this.$message({
          type: 'error',
          message: 'Invalid credentials',
          offset: 200,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-page {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .logo-row {
    margin-bottom: 2rem;
    .img-fluid {
      max-width: 250px;
    }
  }
  .home-img {
    align-items: flex-start;
  }
  .auth-row {
    display: flex;
    align-items: center;
    margin-top: $sp-3x;
    min-height: 50vh;
    .auth-form {
      padding: 1.5rem 1.5rem 3rem 1.5rem;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
      h2 {
        font-weight: 500;
        color: $primary;
        padding-bottom: 2rem !important;
      }
    }
  }
}

@include bp-down(lg) {
  .auth-row {
    margin-top: 0;
    .home-img {
      display: none;
    }
  }
}
</style>
