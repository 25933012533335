<template>
  <div class="container empty-container">
    <div class="row">
      <slot />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.empty-container {
  min-height: 40vh;
  .row {
    border: 1px solid $light-grey;
    padding: 1rem;
    text-align: center;
  }
}
</style>
