<template>
  <main>
    <router-view />
  </main>
</template>

<script>

export default {
  name: 'App',
  async created() {
    await this.$store.dispatch('initLogin');
  }
};
</script>
