import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import store from '@/store';

const routes = [
  {
    path: '/',
    redirect: '/a/dashboard',
  },
  {
    path: '/login',
    name: 'Home',
    component: Home,
  },
  {
    path: '/a',
    name: 'Admin',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/layout/Admin.vue'),
    redirect: '/admin/dashboard',
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'dashboard',
        name: 'AdminDashboard',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/admin/Dashboard.vue'
          ),
      },
      {
        path: 'images',
        name: 'AdminImages',
        component: () =>
          import(/* webpackChunkName: "images" */ '../views/admin/Images.vue'),
      },
      {
        path: 'activity',
        name: 'AdminActivity',
        component: () =>
          import(
            /* webpackChunkName: "activity" */ '../views/admin/Activity.vue'
          ),
      },
      {
        path: 'news',
        name: 'AdminNews',
        component: () =>
          import(/* webpackChunkName: "news" */ '../views/admin/News.vue'),
      },
      {
        path: 'library',
        name: 'AdminLibrary',
        component: () =>
          import(
            /* webpackChunkName: "library" */ '../views/admin/Library.vue'
          ),
      },
      // {
      //   path: 'history',
      //   name: 'AdminHistory',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "history" */ '../views/admin/History.vue'
      //     ),
      // },
      {
        path: 'users',
        name: 'AdminUsers',
        component: () =>
          import(/* webpackChunkName: "users" */ '../views/admin/Users.vue'),
      },
    ],
  },
  {
    path: '/s/:type',
    name: 'Success',
    component: () =>
      import(/* webpackChunkName: "success" */ '../views/layout/Success.vue'),
  },
  {
    path: '/e/:type',
    name: 'Error',
    component: () =>
      import(/* webpackChunkName: "error" */ '../views/layout/Error.vue'),
  },
  {
    path: '/:catchAll(.*)*',
    redirect: { name: 'Error', params: { type: '404' } },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    next();
    return;
  }
  if (store.state.auth.userLoggedIn) {
    next();
  } else {
    next({ name: 'Home' });
  }
});

export default router;
