import api from '@/utils/api';
import jwt_decode from 'jwt-decode';

export default {
  state: {
    userLoggedIn: false,
    user: {}
  },
  mutations: {
    toggleAuth(state) {
      state.userLoggedIn = !state.userLoggedIn;
    },
  },
  actions: {
    initLogin({ commit, dispatch }) {
      const token = localStorage.getItem('access');
      if (token) {
        const decodedToken = jwt_decode(token);
        const expiration = decodedToken.exp;
        const now = Date.now() / 1000;
        if (expiration > now) {
          commit('toggleAuth');
        } else {
          dispatch('logout');
        }
      }
    },
    async login({ commit }, { email, password }) {
      try {
        const response = await api.post('/sessions', {
          email: email,
          password: password,
          remember_me: false
        });

        if (response.status === 200 && response.data.access) {
          localStorage.setItem('access', response.data.access);
          localStorage.setItem('refresh', response.data.refresh);
          localStorage.setItem('user', JSON.stringify(response.data.user));
          commit('toggleAuth');
          return response;
        } else {
          throw new Error('An error occurred during your login. Please try again.');
        }
      } catch (err) {
        return err;
      }
    },
    async logout({ commit }) {
      const token = localStorage.getItem('refresh');
      try {
        const response = await api.delete(`/sessions/${token}`);

        if (response.status === 204) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          localStorage.removeItem('refresh');
          commit('toggleAuth');
        }
      } catch (err) {
        console.log(err);
      }
    },
    async refreshToken() {
      const token = localStorage.getItem('refresh');

      if (!token) return;

      try {
        const response = await api.post('/tokens', {
        refresh: token,
      });
        console.log('refresh', response);
        localStorage.setItem('access', response.data.access);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
    async updatePassword(_, { oldPassword, newPassword }) {
      const token = localStorage.getItem('refresh');
      try {
        const response = await api.patch(
          '/users/current?type=update_password',
          {
            oldPassword,
            newPassword,
          }
        );
        return response.status;
      } catch (err) {
        console.log(err);
      }
    },
    getUser() {
      const fromStorage = localStorage.getItem('user');
      return JSON.parse(fromStorage);
    }
  },
};
