<template>
  <div class="row no-auth-row">
    <div class="col-12">
        <p>Vous devez <router-link :to="{ name: 'Auth'}" class="inline-link">vous connecter</router-link> {{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppNoAuth',
  props: {
    text: {
      type: String,
      default:
        "",
    },
  },
};
</script>

<style lang="scss">
.no-auth-row {
    font-weight: bold;
    font-style: italic;
    font-size: 16px;
    padding: $sp;
}
</style>
