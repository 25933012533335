<template>
  <div class="row form-row">
    <el-form :model="formValues" label-position="top">
      <div class="col-12" v-for="item in form" :key="item.name">
        <el-form-item :label="getLabel(item)">
          <div class="form-error" v-if="formErrors[item.name].length">
            <p v-for="error in formErrors[item.name]">
              {{ error }}
            </p>
          </div>
          <component
            v-if="item.type !== 'image'"
            :is="item.component"
            v-model="formValues[item.name]"
            :placeholder="item.placeholder"
            :type="item.type"
            :multiple="item.multiple"
            size="large"
          >
            <template v-if="item.type === 'checkbox'">{{
              item.label
            }}</template>
            <template v-if="item.type === 'select'">
              <el-option
                v-for="option in item.options"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </template>
          </component>
          <el-upload
            v-else
            :file-list="fileList"
            :limit="1"
            :auto-upload="false"
            :on-change="handleUpload"
            :on-remove="handleRemove"
            list-type="picture-card"
          >
            <template #trigger v-if="showUpload">
              <el-button type="default">Select file</el-button>
            </template>
            <template #tip>
              <div class="el-upload__tip text-red">
                Limit 1 file, only jpg/png files are allowed.
              </div>
            </template>
          </el-upload>
        </el-form-item>
      </div>
      <div class="col-12" :class="`text-${buttonSide}`">
        <button class="btn fs-16 mt-2" @click.prevent="handleSubmit">
          {{ button }}
        </button>
      </div>
    </el-form>
  </div>
</template>

<script>
import Validation from '@/utils/services/Validation';
import Utilities from '@/utils/services/Utilities';

export default {
  name: 'AppForm',
  props: {
    model: {
      type: Object,
      default: {},
    },
    form: {
      type: Array,
      default: [],
    },
    rules: {
      type: Object,
      default: {},
    },
    itemToUpdate: {
      type: Object,
      default: {},
    },
    button: {
      type: String,
      default: '',
    },
    buttonSide: {
      type: String,
      default: 'left',
    },
    type: {
      type: String,
      default: '',
    },
  },
  emits: ['submitted'],
  data() {
    return {
      formValues: this.model,
      formErrors: {},
      fileList: [],
      showUpload: true,
    };
  },
  created() {
    if (this.type === 'create') this.formValues = this.model;

    if (this.type === 'update') {
      Object.keys(this.model).forEach((key) => {
        if (key === 'keywords') this.formValues[key] = this.itemToUpdate.tags;
        else this.formValues[key] = this.itemToUpdate[key];
      });
    }

    const keys = Object.keys(this.model);
    for (let i in keys) this.formErrors[keys[i]] = [];
  },
  methods: {
    handleSubmit() {
      const isValid = Validation.validateForm(this.formValues, this.rules);

      if (isValid) this.$emit('submitted', this.formValues);
      else Utilities.showMessage('error', 'Veuillez vérifier tous les champs.');
    },
    // handleBlur(fieldName) {
    //   const value = this.formValues[fieldName];
    //   const rules = this.rules[fieldName];

    //   let errors = [];

    //   for (let i in rules) {
    //     if (rules[i].type === 'confirm') {
    //       rules[i].comparedValue = this.formValues[rules[i].confirmField];
    //     }
    //     errors.push(Validation.validateField(value, rules[i]));
    //   }

    //   this.formErrors[fieldName] = errors;
    // },
    handlePaste(fieldName) {
      if (
        fieldName === 'email' ||
        fieldName === 'password' ||
        fieldName === 'new_password' ||
        fieldName === 'confirm_password'
      ) {
        this.formValues[fieldName] = '';
        Utilities.showMessage(
          'warning',
          'Copy/paste is disabled for this field.'
        );
      }
    },
    getLabel(item) {
      const isRequired = item.required && item.type !== 'checkbox' ? '*' : '';
      const label = item.type === 'checkbox' ? ' ' : item.label;
      return `${label} ${isRequired}`;
    },
    handleUpload(file) {
      const fileType = file.raw.type;

      if (fileType !== 'image/jpeg' && fileType !== 'image/png') {
        return Utilities.showMessage(
          'error',
          'Only jpg/png files are allowed.'
        );
      }

      this.formValues.image = file.raw;
      this.showUpload = false;
      const el = document.getElementsByClassName('el-upload--picture-card')[0];
      el.style.display = 'none';
    },
    handleRemove() {
      this.formValues.image = null;
      this.showUpload = true;
      const el = document.getElementsByClassName('el-upload--picture-card')[0];
      el.style.display = 'flex';
    },
  },
};
</script>

<style>
.el-select {
  width: 100% !important;
}
</style>
