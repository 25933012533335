<template>
  <div class="row mt-4 mb-4 title-row">
    <div class="col-12">
      <h4>{{ title }}</h4>
      <hr class="hr-primary" v-if="underline" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppTitle',
  props: {
    title: {
      type: String,
      default: '',
    },
    side: {
      type: String,
      default: 'left',
    },
    underline: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
