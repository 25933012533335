export default class Validation {
  static validateEmail(email, rule) {
    if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)) {
      return rule.message;
    }
  }

  static validateConfirm(value, rule, password) {
    console.log(value, rule, password);
    if (value !== password) return rule.message;
  }

  static validateMinLength(value, rule) {
    if (value.length < rule.minLength) {
      return `The field must contain at least ${rule.minLength} characters`;
    }
  }

  static validateMaxLength(value, rule) {
    if (value.length > rule.maxLength) {
      return `The field must contain at most ${rule.maxLength} characters`;
    }
  }

  static validateRequired(value, rule) {
    if (value === '') return rule.message;
  }

  static validateField(value, rule) {
    let error = '';
    switch (rule.type) {
      case 'email':
        return this.validateEmail(value, rule);
      case 'minLength':
        return this.validateMinLength(value, rule);
      case 'maxLength':
        return this.validateMaxLength(value, rule);
      case 'required':
        return this.validateRequired(value, rule);
      case 'confirm':
        return this.validateConfirm(value, rule, rule.comparedValue);
      default:
        return error;
    }
  }

  static validateForm(formValues, formRules) {
    let isValid = true;

    Object.keys(formValues).forEach((key) => {
      const value = formValues[key];
      const rules = formRules[key];

      rules.forEach((rule) => {
        const error = this.validateField(value, rule);
        if (error) isValid = false;
      });
    });

    return isValid;
  }
}
